import React from 'react'
import { LOCATION_LABELS, TREE_LEVELS } from '../../../../services/constants'
import {
  BULB_LIFE_STATUS,
  BULB_PROGRESS_COLORS,
  DEVICE_DETAILS_LABELS,
  DEVICE_NOT_WORKING,
  ERROR_ACTION_LABEL,
  ERROR_MODAL_TITLE,
  ERROR_TEXT,
} from './devices-constants'
import {
  getActivitiesCalendarDate,
  MMMD,
  utcConverter,
  yyyyMMDDHHmm,
} from '../../../../services/dateFormat-service'
import {
  getLabelWithCount,
  getDevicePath,
} from '../../../../services/root/root-service'
import Typography from '@material-ui/core/Typography'
import {
  concat,
  has,
  indexOf,
  map,
  uniqueId,
  isNull,
  isEmpty,
  join,
  floor,
  forEach,
} from 'lodash'

const createDeviceLocationLabel = (locationFilter, nodes, isAllSelected) => {
  const { selectedBuildings, selectedName } = locationFilter

  if (selectedBuildings && selectedBuildings.length > 1 && !isAllSelected) {
    return LOCATION_LABELS.multiple
  }

  if (selectedBuildings && selectedBuildings.length === 1) {
    return selectedName
  }

  if (selectedBuildings && selectedBuildings.length === 0) {
    return
  }

  return LOCATION_LABELS.all
}

const _locationTemplate = (label) =>
  `${DEVICE_DETAILS_LABELS.location}: ${label}`

const getAssignedLocationsLabel = (label, isAllSelected) => {
  if (isAllSelected) {
    return _locationTemplate(DEVICE_DETAILS_LABELS.all)
  }

  return label
    ? _locationTemplate(label)
    : DEVICE_DETAILS_LABELS.assignToLocation
}

const getLastUsedDate = (lastEventDate, timezone) => {
  const eventDate =
    lastEventDate && utcConverter(lastEventDate, yyyyMMDDHHmm, timezone)

  return lastEventDate ? eventDate : ''
}

const getErrorLabels = (error, orgName) => {
  const labels = {
    errorText: error,
    errorActionLabel: ERROR_ACTION_LABEL.devicesList,
    errorModalTitle: ERROR_MODAL_TITLE.deviceSNIncorrect,
  }

  if (error.indexOf('registered') !== -1) {
    labels.errorText = error + ERROR_TEXT

    labels.errorActionLabel =
      error.indexOf(orgName) !== -1
        ? ERROR_ACTION_LABEL.deviceDetails
        : ERROR_ACTION_LABEL.devicesList
    labels.errorModalTitle = ERROR_MODAL_TITLE.alreadyRegistered
  }

  return labels
}

const getErrorAction = (error, orgName, serialNumber, devices, handleClose) => {
  const goToDetails = () => {
    let id
    let type = ''

    forEach(devices, (device) => {
      if (device.deviceSerialNumber === serialNumber) {
        id = device.deviceId
        type = device.deviceSubType
      }
    })

    if (id) {
      handleClose()

      const path = getDevicePath(type)

      window.location.href = `/#/devices/${path}/${id}`
    }
  }

  const goToDevicesList = () => {
    handleClose()
    window.location.href = '/#/devices'
  }

  if (error.indexOf('registered') !== -1) {
    return error.indexOf(orgName) !== -1 ? goToDetails : goToDevicesList
  }

  return goToDevicesList
}

const getQueryForDeviceDetails = (id) =>
  `(deviceId eq ${id})&pageNum=0&pageSize=1&quickCount=true&order.id=desc`

const getFaultsBody = (faults, classes) => {
  const faultsValues = map(faults, (fault) => (
    <Typography key={uniqueId()} variant="subtitle1" color="error">
      {fault}
    </Typography>
  ))

  const faultsTypography = <div className={classes.faults}>{faultsValues}</div>

  return (
    <>
      <Typography
        variant="subtitle1"
        color="error"
        className={classes.cardTitle}
      >
        {getLabelWithCount(faults.length, DEVICE_NOT_WORKING.statement, false)}
      </Typography>
      {faultsTypography}
    </>
  )
}

const getFaultsList = (faults) => (isEmpty(faults) ? '' : join(faults, ', '))

const prepareLocationFiltersForDevice = (nodes, userPref) => {
  const nodeList = concat([], nodes)
  const isAllByUserPref = isNull(userPref)
  const params = {
    selectedName: '',
    selectedBuildings: isAllByUserPref ? null : [],
    indeterminateProperties: [],
  }
  let isParentChecked = false
  let propertyId

  const traverse = function (current) {
    map(current, (item) => {
      let hasChecked = has(item, 'checked')
      let inUserPref = indexOf(userPref, item.id) !== -1
      let isChecked = hasChecked ? item.checked : inUserPref

      if (item.locationType === TREE_LEVELS.PROPERTY) {
        propertyId = item.id
      } else {
        item.propertyId = propertyId
      }

      if (item.children && item.children.length) {
        if (isChecked) {
          if (!isParentChecked || item.locationType === TREE_LEVELS.PROPERTY) {
            params.selectedBuildings.push(item.id)
            params.selectedName = item.name
          }

          if (
            item.locationType !== TREE_LEVELS.PROPERTY &&
            indexOf(params.indeterminateProperties, item.propertyId) === -1
          ) {
            params.indeterminateProperties.push(item.propertyId)
          }
        } else {
          isParentChecked = false

          if (item.locationType === TREE_LEVELS.PROPERTY) {
            if (item.indeterminate) {
              params.indeterminateProperties.push(item.id)
            }
          }

          traverse(item.children)
        }
      } else {
        if (isChecked) {
          if (!isParentChecked && item.locationType !== TREE_LEVELS.PROPERTY) {
            if (
              indexOf(params.indeterminateProperties, item.propertyId) === -1
            ) {
              params.indeterminateProperties.push(item.propertyId)
            }
          }

          if (!isParentChecked || item.locationType === TREE_LEVELS.PROPERTY) {
            params.selectedBuildings.push(item.id)
            params.selectedName = item.name
          }
        } else {
          isParentChecked = false
        }
      }
    })
  }

  if (!isAllByUserPref) {
    traverse(nodeList)
  }

  return params
}

const getLocationNameById = (nodes, id) => {
  let name = ''

  const traverse = function (current, deep) {
    forEach(current, (item) => {
      if (item.id === id) {
        name = item.name
      }

      if (item.children && item.children.length) {
        let nextDeep = deep + 1

        traverse(item.children, nextDeep)
      }
    })
  }

  traverse(nodes, 1)

  return name
}

const calculateBulbLifeParams = (workedMilliseconds = 1, fullBulbHours) => {
  const thirdPartFullHours = fullBulbHours / 3
  const workedHours = floor(workedMilliseconds / 3600000)

  const params = {
    status: BULB_LIFE_STATUS.OK,
    color: BULB_PROGRESS_COLORS.EMPTY,
    workedHours,
  }

  if (workedHours < thirdPartFullHours) {
    params.status = BULB_LIFE_STATUS.OK
    params.color = BULB_PROGRESS_COLORS.OK
  }

  if (
    workedHours > thirdPartFullHours &&
    workedHours < 2 * thirdPartFullHours
  ) {
    params.status = BULB_LIFE_STATUS.WARNING
    params.color = BULB_PROGRESS_COLORS.WARNING
  }

  if (workedHours > 2 * thirdPartFullHours) {
    params.status = BULB_LIFE_STATUS.CRITICAL
    params.color = BULB_PROGRESS_COLORS.CRITICAL
  }

  return params
}

const getDeviceDeleteMessage = (nickName) =>
  `Are you sure you want to delete ${nickName}? Any future usage of this device will be excluded from your dashboard.`

const DEVICES_PATH_REGEXP = /^.+\/#\/devices$/

export {
  createDeviceLocationLabel,
  getAssignedLocationsLabel,
  getLastUsedDate,
  getErrorLabels,
  getErrorAction,
  getQueryForDeviceDetails,
  getFaultsBody,
  getFaultsList,
  _locationTemplate,
  prepareLocationFiltersForDevice,
  calculateBulbLifeParams,
  getDeviceDeleteMessage,
  getLocationNameById,
  DEVICES_PATH_REGEXP,
}
