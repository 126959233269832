import { COLORS, DEVICE_TYPE } from '../../../../services/constants'

import ARCDevicesListContainer from '../../List/ARC/ARCDeviceListContainer'
import React from 'react'
import UVGIDeviceListContainer from '../../List/UVGI/UVGIDeviceListContainer'
import { startCase } from 'lodash'

const INDEXES = {
  one: 'one',
  two: 'two',
}

const NO_DEVICE_TYPES = 'No device types are enabled for this account.'

const DEVICE_MAX_NICKNAME_LENGTH = 30

const LOCATION_LABELS = {
  action: 'Apply',
  modalTitle: 'Select Location(s)',
  roomModalTitle: 'Assign Device to Room',
  fieldLabel: 'Location(s)',
}

const DEVICE_DETAILS_LABELS = {
  assignToLocation: 'Assign to Location',
  all: 'All',
  multiple: 'Multiple',
  nickname: 'Device Nickname',
  saveChanges: 'Save Changes',
  usageData: 'Most Recent Usage Data',
  lastUsed: 'Last Used',
  location: 'Location',
  status: 'Status',
  runTime: 'Run Time',
  errorCode: 'Error Code',
  deleteButtonLabel: 'Delete Device',
  cycleTime: 'Cycle Time',
  cumulativeUsage: 'Bulb lifetime use',
  noActivities: 'No Activities',
  noInformation: 'No information',
}

const DEVICE_NOT_WORKING = {
  question: 'Device not working properly?',
  statement: 'Error Code',
  requestService: 'Request Service',
}

const temporaryError = {
  error: 'Mail service is not configured',
}

const REQUEST_SERVICE_LABELS = {
  pageName: 'Request Service',
  deviceLabel: 'Device # ',
  infoMessage: 'A member of our team will contact you within 24 hours.',
  giveUsACall: 'Questions? Give us a call.\n(801) 823-0314',
  contactLabel: 'Preferred Contact Method',
  messageLabel: 'What is wrong with the device?',
  action: 'Submit',
  close: 'Close',
  thankYou: 'Thank you',
  confirmMessage: 'We will be in touch shortly.',
}

const devicesTypesConfig = [
  {
    id: 'ARC_DEVICES',
    component: (history) => <ARCDevicesListContainer history={history} />,
    index: DEVICE_TYPE.arc,
  },
  {
    id: 'UVGI_DEVICES',
    component: (history) => <UVGIDeviceListContainer history={history} />,
    index: DEVICE_TYPE.uvgi,
  },
  {
    id: 'FAR_UVC',
    component: (history) => <UVGIDeviceListContainer history={history} />,
    index: DEVICE_TYPE.uvc,
  },
]

const DEVICES_PAGE_TITLE = 'Devices'

const DEVICES_DOCUMENT_TITLE = 'Devices | R-Zero'

const ERROR_ACTION_LABEL = {
  deviceDetails: 'Device Details',
  devicesList: 'Devices List',
}

const ERROR_MODAL_TITLE = {
  deviceSNIncorrect: 'Device SN Not Found',
  alreadyRegistered: 'Already Registered',
}

const ERROR_TEXT = '\n\nPlease contact support with any questions.'

const ADD_DEVICE_LABELS = {
  tittle: 'Add Device',
  placeholder: 'Device SN#',
  action: '+ Add Device',
  noLocations: 'The organization has no locations yet.',
  noRooms: 'The organization has no Rooms yet.',
}

const FAULTS_VALUES_LABELS = {
  OVER_HEATED_LED: 'Over Heated LED',
  NON_FUNCTIONING_FAN: 'Non Functioning Fan',
  BAD_MOUNT_CONTACT: 'Bad Mounted Contact',
  BAFFLE_OPEN: 'Baffle Open',
  MANUAL_RESET: 'Manual Reset',
}

const BULB_LIFE_LABELS = {
  title: 'Bulb Life',
  hours: 'Hours Runtime',
  serviceButton: 'Purchase Bulbs',
}

const BULB_LIFE_STATUS = {
  OK: 'ok',
  CRITICAL: 'critical',
  WARNING: 'warning',
}

const BULB_PROGRESS_COLORS = {
  EMPTY: COLORS.GRAY_WHITE,
  WARNING: COLORS.YELLOW,
  OK: COLORS.GREEN,
  CRITICAL: COLORS.RED_ORANGE,
}

const EVENT_DEVICE_STATUS = (deviceStatus, faultStatus) => {
  if (faultStatus) {
    return 'Error'
  }

  if (!deviceStatus) {
    return 'Unknown'
  }

  return startCase(deviceStatus)
}

const DEVICE_STATUS = {
  [null]: 'Unknown',

  status: 'Status',
  OFF: 'Off',
  STAND_BY: 'Standby',
  ACTIVE: 'Active',
  ERROR: 'Error',
  DISCONNECTED: 'Disconnected',
  OFF_ERROR: 'Error',
}

const CHANGES_SAVED_LABEL = 'Changes saved'

const DEVICE_SEARCH = 'Search by Serial number'

export {
  INDEXES,
  NO_DEVICE_TYPES,
  DEVICE_MAX_NICKNAME_LENGTH,
  LOCATION_LABELS,
  DEVICE_DETAILS_LABELS,
  DEVICE_NOT_WORKING,
  temporaryError,
  REQUEST_SERVICE_LABELS,
  devicesTypesConfig,
  DEVICES_PAGE_TITLE,
  DEVICES_DOCUMENT_TITLE,
  ERROR_ACTION_LABEL,
  ERROR_MODAL_TITLE,
  ERROR_TEXT,
  ADD_DEVICE_LABELS,
  FAULTS_VALUES_LABELS,
  BULB_LIFE_LABELS,
  BULB_LIFE_STATUS,
  BULB_PROGRESS_COLORS,
  DEVICE_STATUS,
  CHANGES_SAVED_LABEL,
  DEVICE_SEARCH,
  EVENT_DEVICE_STATUS,
}
