import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import style from './style'
import CloseIcon from '@material-ui/icons/Close'
import { DELETE_USER_LABELS } from '../../services/users-constants'
import actions from '../../services/users-actions'
import IconButton from '@material-ui/core/IconButton'
import ConfirmModal from '../../../../components/_shared/modals/confirmModal/ConfirmModal'
import { checkConnection } from '../../../../services/root/root-service'

const DeleteUserButton = (props) => {
  const { deleteUser, user = {}, error, classes } = props
  const [open, setOpen] = useState(false)

  const changeModal = () => {
    setOpen(!open)
  }

  const onSubmit = () => {
    deleteUser(user.id, changeModal)
  }

  return (
    <>
      <IconButton
        type="submit"
        variant="contained"
        className={classes.deleteButton}
        color="secondary"
        onClick={changeModal}
        title="Delete User"
      >
        <CloseIcon />
      </IconButton>
      <ConfirmModal
        isOpen={open}
        error={error}
        onClose={changeModal}
        onSubmit={onSubmit}
        confirmText={DELETE_USER_LABELS.confirmText(
          `${user.firstName} ${user.lastName}`
        )}
        modalTitle={DELETE_USER_LABELS.deleteUser}
        actionLabel={DELETE_USER_LABELS.delete}
        closeLabel={DELETE_USER_LABELS.close}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id, onSuccess) =>
    dispatch(checkConnection(() => actions.deleteUser(id, onSuccess))),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(DeleteUserButton))
