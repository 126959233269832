const arcDeviceListActions = {}

arcDeviceListActions.actionTypes = {
  LOAD_DEVICES_LIST: '{ARC Device List} Load Devices List',
  LOAD_DEVICES_LIST_SUCCEEDED: '{ARC Device List} Load Devices List Succeeded',
  LOAD_DEVICES_LIST_FAILED: '{ARC Device List} Load Devices List Failed',

  LOAD_LOCATIONS_FOR_LIST: '{ARC Device List} Load Locations for List',
  LOAD_LOCATIONS_FOR_LIST_SUCCEEDED:
    '{ARC Device List} Load Locations for List Succeeded',
  LOAD_LOCATIONS_FOR_LIST_FAILED:
    '{ARC Device List} Load Locations for List Failed',

  LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL:
    '{ARC Device List} Load locations for add device',

  ADD_NEW_DEVICE: '{ARC Device List} Add New Device',
  ADD_NEW_DEVICE_SUCCEEDED: '{ARC Device List} Add New Device Succeeded',
  ADD_NEW_DEVICE_FAILED: '{ARC Device List} Add New Device Failed',

  CHANGE_SORTING: '{ARC Device List} Change Sorting',

  PUT_DEVICE_DETAILS: '{ARC Device List} Put device details',

  RESET_ACTION_ERROR: '{ARC Device List} Reset Action Error',

  SET_GLOBAL_TIMEOUT: '{ARC Device List} Set Global Timeout',
  SET_GLOBAL_TIMEOUT_FAILED: '{ARC Device List} Set Global Timeout Failed',
  SET_GLOBAL_TIMEOUT_SUCCEEDED:
    '{ARC Device List} Set Global Timeout Succeeded',

  RESET_ERROR: '{ARC Device List} Reset Errors',

  SETUP_LOCATIONS_FILTER: '{ARC Device List} Setup locations Filter',
  SETUP_LOCATIONS_FILTER_FAILED:
    '{ARC Device List} Setup locations Filter Failed',

  SET_ROWS_PER_PAGE: '{ARC Device List} Set Rows Per Page',
  GO_TO_NEXT_PAGE: '{ARC Device List} Go To Next Page',

  RESET_STATE: '{ARC Device List} Reset State',
  SEARCH_BY_SN: '{ARC Device List} Search by Serial number',
}

arcDeviceListActions.resetActionError = function () {
  return {
    type: arcDeviceListActions.actionTypes.RESET_ACTION_ERROR,
  }
}

arcDeviceListActions.putDeviceDetails = function (device) {
  return {
    type: arcDeviceListActions.actionTypes.PUT_DEVICE_DETAILS,
    payload: {
      device,
    },
  }
}

arcDeviceListActions.loadDevicesList = function () {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
  }
}

arcDeviceListActions.loadDevicesListSuccess = function (devices) {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

arcDeviceListActions.loadDevicesListFailed = function (message) {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceListActions.loadLocationsForList = function () {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_LIST,
  }
}

arcDeviceListActions.loadLocationsForListSuccess = function (nodes) {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_LIST_SUCCEEDED,
    payload: {
      nodes,
    },
  }
}

arcDeviceListActions.loadLocationsForListFailed = function (message) {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_LIST_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceListActions.addNewDevice = function (serialNumber, onCLose) {
  return {
    type: arcDeviceListActions.actionTypes.ADD_NEW_DEVICE,
    payload: {
      serialNumber,
      onCLose,
    },
  }
}

arcDeviceListActions.addNewDeviceSuccess = function () {
  return {
    type: arcDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
  }
}

arcDeviceListActions.addNewDeviceFailed = function (message, serialNumber) {
  return {
    type: arcDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED,
    payload: {
      message,
      serialNumber,
    },
  }
}

arcDeviceListActions.changeSorting = function (sortBy) {
  return {
    type: arcDeviceListActions.actionTypes.CHANGE_SORTING,
    payload: {
      sortBy,
    },
  }
}

arcDeviceListActions.loadLocationsForAddDeviceModal = function (onSuccess) {
  return {
    type: arcDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    payload: {
      onSuccess,
    },
  }
}

arcDeviceListActions.setGlobalTimeout = function (
  timeout,
  close,
  applyToAllLocations
) {
  return {
    type: arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT,
    payload: {
      timeout,
      close,
      applyToAllLocations,
    },
  }
}

arcDeviceListActions.setGlobalTimeoutSucceeded = function (data) {
  return {
    type: arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT_SUCCEEDED,
    payload: {
      data,
    },
  }
}

arcDeviceListActions.setGlobalTimeoutFailed = function (message) {
  return {
    type: arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceListActions.resetError = function () {
  return {
    type: arcDeviceListActions.actionTypes.RESET_ERROR,
  }
}

arcDeviceListActions.setupLocationsFilter = function (nodes, tree, userId) {
  return {
    type: arcDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

arcDeviceListActions.setupLocationsFilterFailed = function (message) {
  return {
    type: arcDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceListActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: arcDeviceListActions.actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

arcDeviceListActions.changePage = function (newPage) {
  return {
    type: arcDeviceListActions.actionTypes.GO_TO_NEXT_PAGE,
    payload: {
      newPage,
    },
  }
}

arcDeviceListActions.resetState = function () {
  return {
    type: arcDeviceListActions.actionTypes.RESET_STATE,
  }
}

arcDeviceListActions.searchBySn = function (content) {
  return {
    type: arcDeviceListActions.actionTypes.SEARCH_BY_SN,
    payload: {
      content,
    },
  }
}

export default arcDeviceListActions
