import moment from 'moment'

const MMMD = 'MMM D'
const MMM = 'MMM'
const dddd_DMMMMDDYYYY = 'dddd - MMMM DD, YYYY'
const DD = 'DD'
const D = 'D'
const MM = 'MM'
const YYYY = 'YYYY'

const getActivitiesCalendarDate = (date, timezone, format) => {
  let dateString
  const todayWithTimezone = timezone
    ? moment.utc().utcOffset(timezone).format(yyyyMMDFormat)
    : moment.utc().local().format(yyyyMMDFormat)
  const yesterdayWithTimezone = timezone
    ? moment()
        .subtract(1, 'days')
        .utc()
        .utcOffset(timezone)
        .format(yyyyMMDFormat)
    : moment().subtract(1, 'days').utc().local().format(yyyyMMDFormat)

  if (moment(todayWithTimezone).isSame(date, 'day')) {
    dateString = 'Today'
  } else if (moment(yesterdayWithTimezone).isSame(date, 'day')) {
    dateString = 'Yesterday'
  } else {
    dateString = moment(date).format(format || dddd_DMMMMDDYYYY)
  }

  return dateString
}

const utcConverter = (date, format, timezone) => {
  const temp = timezone
    ? moment.utc(date).utcOffset(timezone)
    : moment.utc(date).local()

  return format ? temp.format(format) : temp.format()
}

const utcConverterForCalendar = (date, format) => {
  return date.utc().format(format)
}

const utcConverterToDate = (date, timezone) => {
  return timezone
    ? moment.utc(date).utcOffset(timezone)
    : moment.utc(date).local()
}

const formattedWithTimezoneString = (date, timezone) => {
  const temp = timezone
    ? moment.utc(date).utcOffset(timezone)
    : moment.utc(date).local()

  return temp.format(yyyyMMDFormat)
}

const formattedWithTimezoneStartDayString = (date, timezone, format) => {
  const temp = timezone
    ? moment.utc(date).utcOffset(timezone).startOf('day')
    : moment.utc(date).local().startOf('day')

  return temp.format(format || yyyyMMDFormat)
}

const convertTodayToMomentWithTimezone = (timezone) => {
  const today = moment()
  const temp = timezone ? moment.utc(today).utcOffset(timezone) : today
  const dateString = temp.format(yyyyMMDFormat)

  return moment(dateString)
}

const convertTodayToStringWithTimezone = (timezone, format) => {
  const today = moment()
  const temp = timezone ? moment.utc(today).utcOffset(timezone) : today

  return temp.format(format || yyyyMMDFormat)
}

const hhmmaFormat = 'hh:mm a'
const mssFormat = 'm:ss'
const yyyyMMDFormat = 'yyyy/MM/D'
const yyyyMMdFormat = 'yyyy/MM/d'
const yyyyMMDFormatForCharts = 'yyyy-MM-DD'
const MMDFormat = 'MM/D'
const yyyyMMDDTHHmmss = 'yyyy-MM-DDTHH:mm:ss'
const yyyyMMDDHHmm = 'yyyy-MM-DD HH:mm'
const yyyyMMDD = 'yyyy-MM-DD'
const DDMMyyyyTHHmm = 'DD-MM-yyyyTHH:mm'
const MMddyyyyhmmaa = 'MM/dd/yyyy h:mm aa'
const MMddyyyyhmmssaa = 'MM/dd/yyyy h:mm:ss aa'
const DMMyyyyAndTime = 'M/D/yyyy hh:mm a'
const DMyyyy = 'M/D/yyyy'
const DDMMYYYYFormat = 'DD/MM/YYYY'
const MMMMDDYYYY_hmma = 'MMMM DD, h:mm a'
const MMMMDDYYYY_startOfDay = 'MMMM DD, 12:00 a'

export {
  utcConverter,
  utcConverterToDate,
  utcConverterForCalendar,
  getActivitiesCalendarDate,
  formattedWithTimezoneString,
  convertTodayToMomentWithTimezone,
  convertTodayToStringWithTimezone,
  formattedWithTimezoneStartDayString,
  hhmmaFormat,
  mssFormat,
  yyyyMMDFormat,
  yyyyMMDFormatForCharts,
  MMDFormat,
  yyyyMMDDTHHmmss,
  DDMMyyyyTHHmm,
  MMMD,
  MMM,
  yyyyMMDD,
  MMddyyyyhmmaa,
  MMddyyyyhmmssaa,
  yyyyMMdFormat,
  DMMyyyyAndTime,
  DMyyyy,
  DDMMYYYYFormat,
  DD,
  D,
  MM,
  YYYY,
  MMMMDDYYYY_hmma,
  MMMMDDYYYY_startOfDay,
  yyyyMMDDHHmm,
}
