import Highcharts from 'highcharts'
import { findIndex, forEach, isNull, map, uniqBy } from 'lodash'
import moment from 'moment'
import { TREE_LEVELS, COLORS } from '../../../../services/constants'
import {
  DMyyyy,
  MMDFormat,
  yyyyMMDFormatForCharts,
} from '../../../../services/dateFormat-service'
import filterQuery from '../../../../services/filterQuery-service'
import { prepareTimeFilter } from '../../../Dashboard/services/related/filter-service'
import { SUMMARY_PAGE } from '../../ARC/service/arcSummary-constants'
import {
  CYCLE_CHART,
  SUMMARY_LABELS,
  UVGI_DEVICE_STATUSES,
} from './uvgiSummary-constants'

const returnTempDate = () => {
  return {}
}

const cardsConfig = [
  {
    prop: 'totalCycles',
    label: () => SUMMARY_PAGE.completedCycles,
  },
  {
    prop: 'totalCyclesTime',
    label: (inMinutes) =>
      inMinutes
        ? SUMMARY_PAGE.minutesOfCycleTime
        : SUMMARY_PAGE.hoursOfCycleTime,
    convertToHours: true,
  },
  {
    prop: 'avgCyclesTime',
    label: () => SUMMARY_PAGE.avgTimePerCycle,
    convertToMinutes: true,
  },
]

const prepareQueryForDeviceActivity = (devices) => {
  const serialNumbers = map(devices, (item) => {
    return item.deviceSerialNumber
  })

  return {
    query: filterQuery.getArrayString(
      serialNumbers,
      filterQuery.templates.array,
      'deviceSerialNumber ',
      'in',
      true
    ),
  }
}

const prepareDevicesActivities = (devices, activities) => {
  const activeDevices = []
  let activeCount = 0

  forEach(devices, (item) => {
    let index = findIndex(activities, ['deviceId', item.deviceId])

    if (index > 0) {
      let isActive =
        (activities[index] &&
          activities[index].deviceStatus === UVGI_DEVICE_STATUSES.ACTIVE) ||
        activities[index].deviceStatus === 'in_progress'

      if (isActive) {
        activeCount++
      }

      activeDevices.push({
        ...activities[index],
        ...item,
        isActive,
      })
    }
  })

  return {
    activeDevices,
    activeCount,
  }
}

const prepareLocationsActivities = (locations, activities) => {
  const activeDevices = []

  forEach(locations, (item) => {
    const firstIndex = findIndex(activities, ['locationId', item.locationId])
    const positiveIndex = findIndex(activities, {
      locationId: item.locationId,
      isActive: true,
    })
    const index = positiveIndex !== -1 ? positiveIndex : firstIndex

    activeDevices.push({
      ...item,
      ...activities[index],
    })
  })

  return activeDevices
}

const prepareLocationsForSummary = (activities, dateFilter) => {
  const activeRecords = []

  const locations = map(activities, (item) => {
    let isActive =
      item.deviceStatus === UVGI_DEVICE_STATUSES.ACTIVE ||
      item.deviceStatus === 'in_progress'
    let isCompleted = item.transactionStatus === 'completed'

    if (isActive && dateFilter.today) {
      activeRecords.push(item)
    }

    if (isCompleted && !dateFilter.today) {
      activeRecords.push(item)
    }

    return {
      locationId: item.lastEventLocationId,
      isActive: isActive,
    }
  })

  const activeCount = uniqBy(activeRecords, 'lastEventLocationId').length

  return {
    locations,
    activeCount,
  }
}

const prepareCompletedCycles = (
  dateFilter,
  defaultPeriod,
  timezone,
  cycles
) => {
  const period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)

  const startDate = period.startDate.subtract(1, 'days')
  const endDate = period.endDate

  return getDaysBetweenDates(cycles, startDate, endDate)
}

let getDaysBetweenDates = function (cycles, startDate, endDate) {
  let i = 0

  let currentDate = startDate.clone()
  let dates = []

  while (currentDate.isBefore(endDate)) {
    if (
      !!cycles[i] &&
      currentDate.format(yyyyMMDFormatForCharts) === cycles[i].date
    ) {
      const fullValue = moment.duration(cycles[i].totalDuration)

      const hours = fullValue.asHours()

      dates.push({
        x: currentDate.format(MMDFormat),
        y: hours,
        z: cycles[i].countDevice,
      })

      i++
    } else {
      dates.push({ x: currentDate.format(MMDFormat), y: 0, z: 0 })
    }

    currentDate.add(1, 'days')
  }

  return dates
}

const getTotalLocationCount = (locations) => {
  const rooms = []

  const traverse = function (current) {
    forEach(current, (item) => {
      if (item.locationType === TREE_LEVELS.ROOM && item.checked) {
        rooms.push(item)
      }

      if (item.children && !!item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(locations, 1)

  return rooms.length
}

const createCompletedCyclesChart = (x, y, z, isMobile) => {
  const getOptions = (isMobile, animation = true, extraOptions = {}) => ({
    chart: {
      type: 'column',
      backgroundColor: isMobile ? COLORS.WARM_GRAY : COLORS.WHITE,
      style: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      ...extraOptions,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: x,
      crosshair: true,
    },
    title: {
      text: null,
    },
    yAxis: [
      {
        allowDecimals: false,
        min: 0,
        title: {
          text: null,
        },
      },
      {
        allowDecimals: false,
        min: 0,
        title: {
          text: null,
        },
        opposite: true,
      },
    ],
    plotOptions: {
      column: {
        animation,
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: !isMobile
          ? {
              enabled: true,
              crop: false,
              overflow: 'none',
              color: COLORS.GRAY_STORM_DUST,
              style: {
                fontWeight: 'normal',
              },
            }
          : {},
      },
    },
    series: [
      {
        data: y,
        name: CYCLE_CHART.title,
        color: COLORS.BLUE,
        yAxis: 1,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}',
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><br/><table>',
          pointFormat:
            '<td style="padding:0"><b>{point.y:.1f} hours of cycle time</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
      },
      {
        type: 'spline',
        name: SUMMARY_LABELS.activeDevices,
        data: z,
        color: COLORS.YELLOW,
        marker: {
          lineWidth: 1,
          lineColor: COLORS.YELLOW,
          fillColor: COLORS.YELLOW,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><br/><table>',
          pointFormat:
            '<td style="padding:0"><b>{point.y} active devices</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
      },
    ],
  })
  Highcharts.chart('completed-cycles-container', getOptions(isMobile))
  Highcharts.chart(
    'completed-cycles-container-pdf',
    getOptions(false, false, { height: 300, width: 787, animation: false })
  )
}

const getLabelForDateFilter = (period) => {
  const { startDate, endDate } = period
  const isOneDay = moment(startDate).isSame(endDate, 'day')

  return isOneDay || isNull(endDate)
    ? moment(period.startDate).format(DMyyyy)
    : `${moment(period.startDate).format(DMyyyy)} - ${moment(
        period.endDate
      ).format(DMyyyy)}`
}

export {
  returnTempDate,
  cardsConfig,
  prepareQueryForDeviceActivity,
  prepareDevicesActivities,
  prepareLocationsForSummary,
  prepareCompletedCycles,
  createCompletedCyclesChart,
  getTotalLocationCount,
  getLabelForDateFilter,
  prepareLocationsActivities,
}
