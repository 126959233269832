import { IconButton, InputAdornment } from '@material-ui/core'
import React, { useState } from 'react'

import { DEVICE_SEARCH } from '../services/devices-constants'
import { ENTER_CODE_KEYS } from '../../../../services/constants'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import styles from '../../List/ARC/style'
import { withStyles } from '@material-ui/core/styles'

const DevicesSearch = (props) => {
  const { onSearch, classes } = props

  const onChangeValue = (e) => {
    onSearch(e.target.value.trim())
  }

  return (
    <div className={classes.searchWrapper}>
      <TextField
        className={classes.searchField}
        placeholder={DEVICE_SEARCH}
        onChange={onChangeValue}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { className: classes.input },
        }}
      />
    </div>
  )
}

export default withStyles(styles)(DevicesSearch)
