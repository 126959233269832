import filterQuery from './filterQuery-service'

export const COOKIE_DOMAIN =
  process.env.REACT_APP_AUTH_COOKIE_DOMAIN || 'localhost'
export let HOST = process.env.REACT_APP_API_URL || ''

// @todo: remove this when we remove this package from java.
// This is a temporary solution since right now we can't add env variables in build time.
if (!HOST && window.location?.origin) {
  HOST = `${window.location.origin}`
}

const TOKENS = {
  refreshToken: 'refreshToken',
  idToken: 'idToken',
  tokenIssuer: 'tokenIssuer',
}

const USER_ROLES = {
  globalAdminRole: 'GLOBAL_ADMIN_ROLE',
  accountOwnerRole: 'ACCOUNT_OWNER_ROLE',
  adminUserRole: 'ADMIN_USER_ROLE',
  operatorUserRole: 'OPERATOR_USER_ROLE',
  adminBillingUserRole: 'ADMIN_USER_BILLING_ROLE',
}

const USER_ROLES_LABELS = {
  GLOBAL_ADMIN_ROLE: 'Global Admin',
  ACCOUNT_OWNER_ROLE: 'Account Owner',
  ADMIN_USER_ROLE: 'Admin',
  OPERATOR_USER_ROLE: 'Operator',
  ADMIN_USER_BILLING_ROLE: 'Admin',
}

const STATUS_FILTER_VALUES = {
  completed: 'completed',
  cancelled: 'cancelled',
  paused: 'paused',
  in_progress: 'in_progress',
}

const STATUS_LABELS = {
  [STATUS_FILTER_VALUES.completed]: 'Completed',
  [STATUS_FILTER_VALUES.cancelled]: 'Cancelled',
  [STATUS_FILTER_VALUES.paused]: 'Interrupted',
  [STATUS_FILTER_VALUES.in_progress]: 'In Progress',
}

const DATE_FILTER = {
  today: 'Today',
  week: 'Past 7 days',
  month: 'Past Month',
  custom: 'Custom',
}

const DATA_SORTING = {
  label: 'Sort By',
}

const ACTION_LABELS = {
  apply: 'Apply',
}

const MONTH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const UNKNOWN_LOCATION = 'Unknown Location'

const UNKNOWN_ID = 'unknown'

const TREE_LEVELS = {
  PROPERTY: 'property',
  BUILDING: 'building',
  ZONE: 'zone',
  ROOM: 'room',
  LOCATIONS: 'locations',
  PLACEMENT: 'placement',
}

const PREF = {
  LOCATIONS: 'pref.locations',
  INDETERMINATE: 'pref.indeterminate_locations',
  UNKNOWN_LOCATIONS: 'pref.unknown_locations',
  DELETED_LOCATIONS: 'pref.deleted_locations',
  SERVER_PREF: 'pref.server_pref',
}

const LOCATION_LABELS = {
  assignToLocation: 'Assign to Location',
  all: 'All',
  multiple: 'Multiple',
  location: 'Location',
  none: '--',
  allProperties: 'All Properties',
  multipleLocations: 'Multiple Locations',
  multipleProperties: 'Multiple Properties',
}

const deeps = ['area', 'property', 'building', 'zone', 'room', 'placement']

const rZero = 'R-Zero'

const PAGINATION = {
  perPage: 'Per Page',
  perPageValues: [25, 50, 75],
  results: 'Results:',
  of: 'of',
  ofMore: 'of more than',
  apply: 'Apply',
}

const LOCATION_FILTER_LABELS = {
  actionLabel: 'Apply',
  modalTitle: 'Location Filter',
  chipLabel: 'Location: ',
  all: 'All',
  includeUnknownLocations: 'Include Unknown Locations',
  includeDeletedLocations: 'Include Deleted Locations',
  resetFilter: 'Reset Filters',
}

const IDS_FOR_FEATURE_TOGGLING = {
  cycleTime: 'LOC_TIMEOUT',
  arcDevices: 'ARC_DEVICES',
  uvgiDevices: 'UVGI_DEVICES',
  extDevice: 'EXT_DEVICE',
}

const MAX_NAME_LENGTH = 30

const DEVICE_TYPE = {
  arc: 'UVI_ARC',
  uvgi: 'UR_UVGI',
  uvc: 'FAR_UVC',
  ext: 'EXT_DEVICE',
}

const ARC_TYPES = ['UVI_ARC', 'UVI_ARC_REV_B', 'UVI_ARC_REV_D']

const INDEX_MAP = {
  ARC_DEVICES: 'UVI_ARC',
  UVGI_DEVICES: 'UR_UVGI',
  FAR_UVC: 'FAR_UVC',
}

const DEVICE_TYPE_LABEL = {
  arc: 'Arc',
  uvgi: 'Beam',
}

const MAIN_LABELS = {
  none: '--',
}

const INDEXES = {
  arc: 'arc',
  uvgi: 'uvgi',
}

const devicesConfig = [
  {
    id: 'ARC_DEVICES',
    index: DEVICE_TYPE.arc,
  },
  {
    id: 'UVGI_DEVICES',
    index: DEVICE_TYPE.uvgi,
  },
  {
    id: 'FAR_UVC',
    index: DEVICE_TYPE.uvc,
  },
  {
    id: 'EXT_DEVICE',
    index: DEVICE_TYPE.uvc,
  },
]

const DEVICE_TYPE_MAPPER = {
  UVI_ARC: 'Arc',
  UR_UVGI: 'Beam',
  FAR_UVC: 'Vive',
  EXT_DEVICE: 'External',
}

const DEVICE_TYPES = {
  uvgi: {
    type: DEVICE_TYPE.uvgi,
    deviceSubType: DEVICE_TYPE.uvgi,
    path: 'ur-uvgi',
  },
  arc: {
    type: DEVICE_TYPE.arc,
    deviceSubType: DEVICE_TYPE.arc,
    path: 'uvi-arc',
  },
  uvc: {
    type: DEVICE_TYPE.uvgi,
    deviceSubType: DEVICE_TYPE.uvc,
    path: 'far-uvc',
  },
  ext: {
    type: DEVICE_TYPE.ext,
    deviceSubType: DEVICE_TYPE.ext,
    path: 'ext-device',
  },
}

const EMPTY_PAGE_TEXT = 'There is no data to display'

const ENTER_CODE_KEYS = {
  key: 'Enter',
  code: 13,
}

const GENERAL_ACTIVITY_INFO_LABELS = {
  startTime: 'Start Time',
}

const TREE_STATE_ALL = {
  selectedName: '',
  selectedBuildings: null,
  indeterminateProperties: [],
}

const autoHideDuration = 5000

const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GRAFIT: '#29292A',
  BLUE: '#1796FF',
  WARM_BLUE: '#E8F4FF',
  DODGER_BLUE: '#1996FF',
  BLUE_SAN_MARINO: '#1069B2',
  PICTON_BLUE: '#64B5F6',
  BLUE_BELL: '#ABB2DE',
  GRAY: '#6D6E70',
  CONTRAST_GRAY: '#3F464D',
  COLD_GRAY: '#F4F7F9',
  WARM_GRAY: '#F8F8F8',
  DARK_GRAY: '#767778',
  WHITE_SMOKE_GRAY: '#F3F3F3',
  ATHENS_GRAY: '#E8EBEE',
  BLUE_GRAY: '#EBEEF1',
  BLUE_GRAY_SECONDARY: '#EFEFEF',
  LIGHT_GRAY: '#E0E0E0',
  LIGHT_GRAY_SECONDARY: '#F0F0F0',
  LIGHT_SILVER: '#D0D2D3',
  GRAY_WHITE: '#E9EAEA',
  GRAY_SILVER_CHALICE: '#AEAEAE',
  SHUTTLE_GRAY: '#5C666F',
  GRAY_BOULDER: '#757575',
  GRAY_DIMGRAY: '#686868',
  GRAY_STORM_DUST: '#666666',
  GRAY_MOUNTAIN_MIST: '#929497',
  GRAY_MOUNTAIN_MIST_SECONDARY: '#979797',
  GRAY_OUTER_SPACE: '#303539',
  GRAY_IRON: '#D5D6D7',
  GRAY_GAINSBORO: '#DFDFDF',
  GRAY_GAINSBORO_SECONDARY: '#DEDEDE',
  GRAY_ALTO: '#D8D8D8',
  GRAY_FOG: '#D2D2D2',
  GRAY_MERCURY: '#E6E6E6',
  GRAY_SILVER_SAND: '#C5C5C6',
  GRAY_LINK_WATER: '#CBCCCD',
  GRAY_ZIRCON: '#DFE0E0',
  RED: '#DC3838',
  RED_ORANGE: '#F14E22',
  RED_CINNABAR: '#DB3131',
  GREEN: '#37B34A',
  ROYAL_PURPLE: '#673F99',
  YELLOW: '#F1C722',
  ORANGE_NEON_CARROT: '#F7A440',
  ORANGE_BRANDY_PUNCH: '#E28C36',
  BISQUE: '#FFE4C4',
}

const IMAGE_ALT = {
  LOGO: 'R-ZERO logo',
  ARC_DEVICE: 'Arc device image',
}

const DEVICE_REMOVED_QUERY = filterQuery.getQueryString(
  false,
  filterQuery.templates.array,
  'deviceRemoved',
  'eq'
)

const ORDER = {
  asc: 'asc',
  desc: 'desc',
}

const PAGES = [
  { title: 'Summary', pattern: /^\/summary$/ },
  { title: 'Dashboard', pattern: /^\/dashboard$/ },
  { title: 'Locations', pattern: /^\/locations$/ },
  { title: 'Devices', pattern: /^\/devices$/ },
  { title: 'Users', pattern: /^\/users$/ },
  { title: 'Settings', pattern: /^\/account$/ },
  { title: 'Login', pattern: /^\/login$/ },
  { title: 'API Key', pattern: /^\/api_key$/ },
  { title: 'Detailed locations report', pattern: /^\/summary\/.+\/locations/ },
  { title: 'Detailed devices report', pattern: /^\/summary\/.+\/devices/ },
  { title: 'Device details', pattern: /^\/devices\/.+/ },
]

export {
  TOKENS,
  USER_ROLES,
  USER_ROLES_LABELS,
  STATUS_FILTER_VALUES,
  STATUS_LABELS,
  DATE_FILTER,
  MONTH,
  DATA_SORTING,
  ACTION_LABELS,
  UNKNOWN_LOCATION,
  UNKNOWN_ID,
  TREE_LEVELS,
  PREF,
  LOCATION_LABELS,
  deeps,
  rZero,
  PAGINATION,
  LOCATION_FILTER_LABELS,
  IDS_FOR_FEATURE_TOGGLING,
  MAX_NAME_LENGTH,
  DEVICE_TYPE,
  DEVICE_TYPE_LABEL,
  MAIN_LABELS,
  INDEXES,
  devicesConfig,
  INDEX_MAP,
  EMPTY_PAGE_TEXT,
  ENTER_CODE_KEYS,
  DEVICE_TYPES,
  DEVICE_TYPE_MAPPER,
  GENERAL_ACTIVITY_INFO_LABELS,
  TREE_STATE_ALL,
  COLORS,
  autoHideDuration,
  ARC_TYPES,
  IMAGE_ALT,
  DEVICE_REMOVED_QUERY,
  ORDER,
  PAGES,
}
