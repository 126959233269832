import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import uvgiDeviceListActions from './uvgiDeviceList-actions'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { PREF, TREE_LEVELS } from '../../../../../services/constants'
import { prepareQueryForRequest } from './uvgiDeviceList-service'
import { cloneDeep, compact } from 'lodash'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import { getTreeNodesWithSelectableLevel } from '../../../../../components/_shared/customTree/services/customTree-service'
import { getPropertyIdWithSelectedRoom } from '../../../Details/UVGI/services/uvgiDeviceDetails-service'

const getCurrentUserId = (state) => state.rootReducer.userId
const getLocationFilter = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.locationFilter
const getRoomsFilter = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.roomsFilter
const getLocationTree = (state) => state.customTreeReducer.nodes
const getActiveTab = (state) => state.devicesReducer.sharedDevicesReducer.tab
const getLocationCopyTree = (state) => state.customTreeReducer.treeCopy
const getLastModification = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.lastModification
const getNeedToUpdateData = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.needToUpdateData
const getReqForDevicesIsExecuted = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.reqForDevicesIsExecuted
const getCurrentPage = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.page
const getRowsPerPage = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.rowsPerPage
const getSearchField = (state) =>
  state.devicesReducer.UVGIDeviceListReducer.searchField
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* pollLastModification(action) {
  while (true) {
    try {
      let reqForDevicesIsExecuted = yield select(getReqForDevicesIsExecuted)

      if (reqForDevicesIsExecuted) {
        let needToUpdateDevices = yield select(getNeedToUpdateData)
        const lastModification = yield select(getLastModification)

        const data = yield call(() => {
          return HttpClient.get(
            `/api/secured/organizations/last-modification/EVENT`
          )
        })

        if (data.message) {
          yield put(uvgiDeviceListActions.pollModificationFailed(data.message))
        } else {
          if (data['modified'] !== lastModification) {
            needToUpdateDevices = true
          }

          yield put(
            uvgiDeviceListActions.pollModificationSucceeded(data['modified'])
          )

          if (needToUpdateDevices) {
            yield put(uvgiDeviceListActions.loadDevicesList())
          }
        }
      }

      yield delay(3000)
    } catch (e) {
      yield put(uvgiDeviceListActions.pollModificationFailed(e.message))
    }
  }
}

function* loadDevicesList() {
  try {
    const activeTab = yield select(getActiveTab)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/last-events`)
    })

    if (data.message) {
      yield put(uvgiDeviceListActions.loadDevicesListFailed(data.message))
    } else {
      yield put(
        uvgiDeviceListActions.putDevicesList(
          compact(data).filter((device) => device.deviceSubType === activeTab)
        )
      )
    }
  } catch (e) {
    yield put(uvgiDeviceListActions.loadDevicesListFailed(e.message))
  }
}

function* loadLocations() {
  try {
    const userId = yield select(getCurrentUserId)
    const activeTab = yield select(getActiveTab)
    const locationFilter = yield select(getLocationFilter)
    const locations = locationFilter[activeTab].locations
    const tree = yield select(getInitialNodesTree)

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        {
          ...getTreeNodesWithSelectableLevel(tree, TREE_LEVELS.ROOM),
          [PREF.SERVER_PREF]: locations,
          isServerPref: true,
        },
        TREE_LEVELS.ROOM
      )
    )
    const locationTree = yield select(getLocationTree)

    yield put(
      uvgiDeviceListActions.loadLocationTreeSuccess(
        locationTree,
        locations,
        activeTab
      )
    )
  } catch (e) {
    yield put(uvgiDeviceListActions.loadLocationTreeFailed(e.message))
  }
}

function* loadLocationsForAddDeviceButton(action) {
  try {
    const { onSuccess } = action.payload
    const tree = yield select(getLocationCopyTree)

    if (tree.message) {
      yield put(
        uvgiDeviceListActions.loadLocationsForDeviceFailed(tree.message)
      )
    } else {
      yield put(
        uvgiDeviceListActions.setupTreeForAddDeviceModal(
          prepareLocationFiltersForDevice(tree.children, []),
          tree
        )
      )

      if (onSuccess) {
        onSuccess()
      }
    }
  } catch (e) {
    yield put(uvgiDeviceListActions.loadLocationsForDeviceFailed(e.message))
  }
}

function* addNewDevice(action) {
  try {
    const locationFilter = yield select(getRoomsFilter)
    const { selectedBuildings: ids } = locationFilter

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/ur-uvgi/${action.payload.serialNumber}/register`,
        {
          body: {
            locationIds: ids && ids.length ? ids : null,
          },
        }
      )
    })

    if (data.message) {
      yield put(
        uvgiDeviceListActions.addNewDeviceFailed(
          data.message,
          action.payload.serialNumber
        )
      )
    } else {
      action.payload.onCLose()

      yield put(uvgiDeviceListActions.addNewDeviceSuccess(data))
    }
  } catch (e) {
    yield put(
      uvgiDeviceListActions.addNewDeviceFailed(
        e.message,
        action.payload.serialNumber
      )
    )
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, tree, userId } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, nodes)
      )
    )

    const locationTree = yield select(getLocationTree)
    const propertyId = getPropertyIdWithSelectedRoom(locationTree)

    yield put(customTreeActions.setPropertyExpandNode(propertyId))
  } catch (e) {
    yield put(uvgiDeviceListActions.setupLocationsFilterFailed(e.message))
  }
}

function* pollLastModificationSaga() {
  while (true) {
    const action = yield take(
      uvgiDeviceListActions.actionTypes.START_POLLING_MODIFICATION
    )

    yield race([
      call(pollLastModification, action),
      take(uvgiDeviceListActions.actionTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* loadLocationsTreeSaga() {
  yield takeLatest(
    uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS,
    loadLocations
  )
}

function* loadLocationsSaga() {
  while (true) {
    const action = yield take(
      uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_SUCCEEDED
    )

    yield race([
      call(pollLastModification, action),
      take(uvgiDeviceListActions.actionTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* addNewDeviceSaga() {
  yield takeLatest(
    uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE,
    addNewDevice
  )
}

function* openAddDeviceModalTreeSaga() {
  yield takeEvery(
    uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    loadLocationsForAddDeviceButton
  )
}

function* loadDevicesListSaga() {
  yield all([
    yield takeEvery(
      uvgiDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
      loadDevicesList
    ),
    yield takeEvery(
      uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
      loadDevicesList
    ),
    yield takeEvery(
      uvgiDeviceListActions.actionTypes.SEARCH_BY_SN,
      loadDevicesList
    ),
    yield takeEvery(
      uvgiDeviceListActions.actionTypes.SETUP_TREE_FOR_ADD_DEVICE_MODAL,
      setupLoadedLocations
    ),
  ])
}

export default function* UVGIDeviceListSaga() {
  yield fork(loadLocationsTreeSaga)
  yield fork(loadLocationsSaga)
  yield fork(pollLastModificationSaga)
  yield fork(addNewDeviceSaga)
  yield fork(openAddDeviceModalTreeSaga)
  yield fork(loadDevicesListSaga)
}
