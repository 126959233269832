import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import DevicesSearch from '../../_shared/components/DevicesSearch'
import devicesActions from '../../_shared/services/devices-actions'
import ARCDeviceList from './components/ARCDeviceList'
import SortButton from './components/SortButton'
import actions from './services/arcDeviceList-actions'
import styles from './style'
import Loader from '../../../../components/_shared/loader/Loader'
import ActionsWrapper from './components/ActionsWrapper'
import { find } from 'lodash'
import { IDS_FOR_FEATURE_TOGGLING } from '../../../../services/constants'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import { DEVICES_DOCUMENT_TITLE } from '../../_shared/services/devices-constants'

const ARCDeviceListContainer = (props) => {
  const {
    history,
    features,
    error,
    networkError,
    isEditView,
    isLoading,
    loadDevicesList,
    isMobile,
    clearNodes,
    addNewDevice,
    resetState,
    searchBySn,
    classes,
  } = props
  const [enableSetCycle, setEnableSetCycle] = useState(null)

  const stylesDependingOnCycle = enableSetCycle
    ? classes.infoWrapperWhenCycleEnabled
    : classes.infoWrapper
  const wrapperStyle = isMobile
    ? stylesDependingOnCycle
    : classes.desktopContentWrapper

  useEffect(() => {
    document.title = DEVICES_DOCUMENT_TITLE

    loadDevicesList()

    return () => {
      clearNodes()
      resetState()
    }
  }, [])

  useEffect(() => {
    if (features && features.length) {
      const featureCycleTime = find(
        features,
        (item) => item.id === IDS_FOR_FEATURE_TOGGLING.cycleTime
      )

      setEnableSetCycle(featureCycleTime && featureCycleTime.enabled)
    }
  }, [features])

  const onAddDevice = (serialNumber, onClose) => {
    addNewDevice(serialNumber, onClose)
  }

  return (
    <Loader isLoading={isLoading}>
      <div className={classes.devicePage}>
        <div className={wrapperStyle}>
          {networkError ? (
            <NetworkError />
          ) : (
            <>
              <div className={classes.buttonsWrapper}>
                <SortButton />
                {!isMobile && isEditView && (
                  <ActionsWrapper
                    enableSetCycle={enableSetCycle}
                    onAddDevice={onAddDevice}
                    error={error}
                  />
                )}
              </div>
              <DevicesSearch onSearch={searchBySn} />
              <div className={classes.listWrapper}>
                <ARCDeviceList history={history} />
              </div>
            </>
          )}
        </div>
        {isMobile && !networkError && isEditView && (
          <ActionsWrapper
            enableSetCycle={enableSetCycle}
            onAddDevice={onAddDevice}
            error={error}
          />
        )}
      </div>
    </Loader>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewDevice: (serialNumber, onCLose) =>
      dispatch(
        checkConnection(() => actions.addNewDevice(serialNumber, onCLose))
      ),
    loadDevicesList: () => dispatch(checkConnection(actions.loadDevicesList)),
    clearNodes: () => dispatch(devicesActions.clearNodes()),
    resetState: () => dispatch(actions.resetState()),
    searchBySn: (content) => dispatch(actions.searchBySn(content)),
  }
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceListReducer },
    rootReducer,
  } = state

  return {
    error: ARCDeviceListReducer.actionError,
    isEditView: !rootReducer.isOperatorView,
    isLoading: ARCDeviceListReducer.isLoading,
    features: rootReducer.features,
    networkError: rootReducer.networkError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(ARCDeviceListContainer)))
