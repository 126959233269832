const uvgiDeviceListActions = {}

uvgiDeviceListActions.actionTypes = {
  START_POLLING_MODIFICATION: '{UVGI Device List} Load Last Modification',
  POLL_MODIFICATION_SUCCEEDED:
    '{UVGI Device List} Load Last Modification Succeeded',
  POLL_MODIFICATION_FAILED: '{UVGI Device List} Load Last Modification Failed',

  STOP_POLLING_MODIFICATION: '{UVGI Device List} Stop Load Last Modification',

  LOAD_DEVICES_LIST: '{UVGI Device List} Load Devices List',
  LOAD_DEVICES_LIST_FAILED: '{UVGI Device List} Load Devices List Failed',
  PUT_DEVICES_LIST: '{UVGI Device List} Put Devices List',

  LOAD_LOCATIONS: '{UVGI Device List} Load Locations',
  LOAD_LOCATIONS_SUCCEEDED: '{UVGI Device List} Load Locations Succeeded',
  LOAD_LOCATIONS_FAILED: '{UVGI Device List} Load Locations Failure',

  APPLY_LOCATION_FILTER: '{UVGI Device List} Apply Location Filter',
  APPLY_LOCATION_FILTER_FAILED:
    '{UVGI Device List} Apply Location Filter Failure',

  SETUP_TREE_FOR_ADD_DEVICE_MODAL:
    '{UVGI Device List} Setup tree for add device',
  LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL:
    '{UVGI Device List} Load locations for add list',

  SETUP_LOCATIONS_FILTER_FAILED:
    '{UVGI Device List} Setup locations Filter Failed',

  SETUP_ROOMS_FILTER: '{UVGI Device List} Setup rooms Filter',
  RESET_ROOMS_FILTER: '{UVGI Device List} Reset rooms Filter',

  ADD_NEW_DEVICE: '{UVGI Device List} Add New Device',
  ADD_NEW_DEVICE_SUCCEEDED: '{UVGI Device List} Add New Device Succeeded',
  ADD_NEW_DEVICE_FAILED: '{UVGI Device List} Add New Device Failed',

  RESET_ACTION_ERROR: '{UVGI Device List} Reset Action Error',

  SET_LOCATION_FILTER_LABEL: '{UVGI Device List} Set Location Filter Label',

  RESET_STATE: '{UVGI Device List} Reset State',
  SEARCH_BY_SN: '{UVGI Device List} Search by Serial number',
}

uvgiDeviceListActions.startPollingModification = (id, needToPoll) => {
  return {
    type: uvgiDeviceListActions.actionTypes.START_POLLING_MODIFICATION,
    payload: {
      id,
      needToPoll,
    },
  }
}

uvgiDeviceListActions.pollModificationSucceeded = (lastModification) => {
  return {
    type: uvgiDeviceListActions.actionTypes.POLL_MODIFICATION_SUCCEEDED,
    payload: {
      lastModification,
    },
  }
}

uvgiDeviceListActions.pollModificationFailed = (message) => {
  return {
    type: uvgiDeviceListActions.actionTypes.POLL_MODIFICATION_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceListActions.stopPollingModification = () => {
  return {
    type: uvgiDeviceListActions.actionTypes.STOP_POLLING_MODIFICATION,
  }
}

uvgiDeviceListActions.loadDevicesList = () => ({
  type: uvgiDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
})

uvgiDeviceListActions.putDevicesList = (devices) => ({
  type: uvgiDeviceListActions.actionTypes.PUT_DEVICES_LIST,
  payload: {
    devices,
  },
})

uvgiDeviceListActions.loadDevicesListFailed = (message) => ({
  type: uvgiDeviceListActions.actionTypes.LOAD_DEVICES_LIST_FAILED,
  payload: {
    message,
  },
})

uvgiDeviceListActions.loadLocations = () => {
  return {
    type: uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS,
  }
}

uvgiDeviceListActions.loadLocationTreeSuccess = (
  nodes,
  locationFilter,
  tab
) => ({
  type: uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_SUCCEEDED,
  payload: {
    nodes,
    locationFilter,
    tab,
  },
})

uvgiDeviceListActions.loadLocationTreeFailed = (message) => ({
  type: uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_FAILED,
  payload: {
    message,
  },
})

uvgiDeviceListActions.applyLocationFilter = (nodes, tab) => ({
  type: uvgiDeviceListActions.actionTypes.APPLY_LOCATION_FILTER,
  payload: {
    nodes,
    tab,
  },
})

uvgiDeviceListActions.loadLocationsForAddDeviceModal = function (onSuccess) {
  return {
    type: uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    payload: {
      onSuccess,
    },
  }
}

uvgiDeviceListActions.setupTreeForAddDeviceModal = function (
  nodes,
  tree,
  userId
) {
  return {
    type: uvgiDeviceListActions.actionTypes.SETUP_TREE_FOR_ADD_DEVICE_MODAL,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

uvgiDeviceListActions.addNewDevice = (serialNumber, onCLose) => {
  return {
    type: uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE,
    payload: {
      serialNumber,
      onCLose,
    },
  }
}

uvgiDeviceListActions.addNewDeviceSuccess = () => ({
  type: uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
})

uvgiDeviceListActions.addNewDeviceFailed = (message, serialNumber) => ({
  type: uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED,
  payload: {
    message,
    serialNumber,
  },
})

uvgiDeviceListActions.resetActionError = () => ({
  type: uvgiDeviceListActions.actionTypes.RESET_ACTION_ERROR,
})

uvgiDeviceListActions.setLocationFilterLabel = (label, tab) => ({
  type: uvgiDeviceListActions.actionTypes.SET_LOCATION_FILTER_LABEL,
  payload: {
    label,
    tab,
  },
})

uvgiDeviceListActions.resetRoomsFilter = () => {
  return {
    type: uvgiDeviceListActions.actionTypes.RESET_ROOMS_FILTER,
  }
}

uvgiDeviceListActions.setupRoomsFilter = (nodes, tree, userId) => {
  return {
    type: uvgiDeviceListActions.actionTypes.SETUP_ROOMS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

uvgiDeviceListActions.setupLocationsFilterFailed = (message) => {
  return {
    type: uvgiDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceListActions.applyLocationFilterFailed = (message) => ({
  type: uvgiDeviceListActions.actionTypes.APPLY_LOCATION_FILTER_FAILED,
  payload: {
    message,
  },
})

uvgiDeviceListActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: uvgiDeviceListActions.actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

uvgiDeviceListActions.changePage = function (page) {
  return {
    type: uvgiDeviceListActions.actionTypes.CHANGE_PAGE,
    payload: {
      page,
    },
  }
}

uvgiDeviceListActions.searchBySn = function (content) {
  return {
    type: uvgiDeviceListActions.actionTypes.SEARCH_BY_SN,
    payload: {
      content,
    },
  }
}

uvgiDeviceListActions.resetState = function () {
  return {
    type: uvgiDeviceListActions.actionTypes.RESET_STATE,
  }
}

export default uvgiDeviceListActions
