import { PAGINATION } from '../../../../../services/constants'
import { getAllIds, getSelectedIds } from '../../../../../services/uvgi-service'
import locationActions from '../../../../Locations/service/location-actions'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import uvgiDeviceDetailsActions from '../../../Details/UVGI/services/uvgiDeviceDetails-actions'
import uvgiDeviceListActions from './uvgiDeviceList-actions'
import { isEmpty, compact } from 'lodash'
import {
  initialLocationFilter,
  initialStateUVGI,
} from './uvgiDeviceList-constants'
import authorizationActions from '../../../../LogIn/services/authorization-actions'

export const UVGIDeviceListReducer = (state = initialStateUVGI, action) => {
  switch (action.type) {
    case uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case uvgiDeviceListActions.actionTypes.PUT_DEVICES_LIST: {
      const { devices } = action.payload

      return {
        ...state,
        list: compact(devices || []),
        loadDevicesError: '',
        isLoading: false,
        needToUpdateData: false,
        reqForDevicesIsExecuted: true,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS:
    case uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE: {
      return {
        ...state,
        list: action.payload.devices || [],
        loadDevicesError: '',
        isLoading: false,
        needToUpdateData: false,
      }
    }
    case uvgiDeviceListActions.actionTypes.LOAD_DEVICES_LIST: {
      return {
        ...state,
        reqForDevicesIsExecuted: false,
      }
    }
    case uvgiDeviceListActions.actionTypes.LOAD_DEVICES_LIST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isInitialDetailsState: false,
        isLoading: false,
        reqForDevicesIsExecuted: true,
      }
    }
    case uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_FAILED:
    case uvgiDeviceListActions.actionTypes.APPLY_LOCATION_FILTER_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isInitialDetailsState: false,
        isLoading: false,
      }
    }
    case uvgiDeviceListActions.actionTypes.LOAD_LOCATIONS_SUCCEEDED:
      const { nodes, locationFilter, tab } = action.payload
      const locations =
        !locationFilter || isEmpty(locationFilter)
          ? prepareLocationFiltersForDevice(nodes)
          : locationFilter
      const stateIds = state.locationFilter[tab].ids

      // is first loading and no locations prefs git all rooms ids
      // if something was checked need to setup only checked
      const ids =
        stateIds && stateIds.length
          ? stateIds
          : isEmpty(locations)
          ? getAllIds(nodes)
          : getSelectedIds(nodes, locations.selectedBuildings)

      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          [tab]: {
            ...state.locationFilter[tab],
            locations,
            ids,
          },
        },
        needToUpdateData: true,
      }
    case uvgiDeviceListActions.actionTypes.APPLY_LOCATION_FILTER: {
      const { nodes, tab } = action.payload

      const locations = prepareLocationFiltersForDevice(nodes)
      const ids = getSelectedIds(nodes, locations.selectedBuildings)

      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          [tab]: {
            ...state.locationFilter[tab],
            locations,
            ids,
          },
        },
        isLoading: false,
        needToUpdateData: true,
      }
    }
    case uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case uvgiDeviceListActions.actionTypes.RESET_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: {},
      }
    case locationActions.actionsTypes.DELETE_LOCATION_SUCCEEDED:
    case locationActions.actionsTypes
      .DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED:
    case locationActions.actionsTypes.ADD_NEW_CHILD_LOCATION_SUCCEEDED:
    case locationActions.actionsTypes.ADD_NEW_LOCATION_SUCCEEDED:
      return {
        ...state,
        locationFilter: { ...initialLocationFilter },
      }
    case uvgiDeviceListActions.actionTypes.SETUP_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case uvgiDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED: {
      return {
        ...state,
        failedSerialNumber: action.payload.serialNumber,
        actionError: action.payload.message,
        isLoading: false,
      }
    }
    case uvgiDeviceListActions.actionTypes.RESET_ACTION_ERROR: {
      return {
        ...state,
        actionError: '',
      }
    }
    case uvgiDeviceListActions.actionTypes.SET_LOCATION_FILTER_LABEL: {
      const { tab, label } = action.payload

      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          [tab]: {
            ...state.locationFilter[tab],
            label,
          },
        },
      }
    }
    case uvgiDeviceListActions.actionTypes.START_POLLING_MODIFICATION:
    case uvgiDeviceListActions.actionTypes.STOP_POLLING_MODIFICATION:
      return {
        ...state,
        polling: false,
      }
    case uvgiDeviceListActions.actionTypes.POLL_MODIFICATION_SUCCEEDED:
      return {
        ...state,
        lastModification: action.payload.lastModification,
      }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED:
      return {
        ...initialStateUVGI,
      }
    case uvgiDeviceListActions.actionTypes.SEARCH_BY_SN: {
      return {
        ...state,
        searchField: action.payload.content,
      }
    }
    case uvgiDeviceListActions.actionTypes.RESET_STATE: {
      return {
        ...state,
        list: [],
        searchField: '',
      }
    }
    default:
      return state
  }
}
