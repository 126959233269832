import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../../../../components/_shared/errors/ErrorHasOccurred'
import actions from '../services/arcDeviceList-actions'
import DeviceCard from './deviceCard/DeviceCard'
import EmptyDevicesPageComponent from '../../../_shared/components/emptyDevicesPage/EmptyDevicesPage'
import { DEVICE_TYPES } from '../../../../../services/constants'
import { gaEventAction } from '../../../../../services/googleAnalytics/ga-actions'
import { uniqBy, uniq, orderBy } from 'lodash'

const ARCDeviceList = (props) => {
  const {
    loadDevicesError,
    putDeviceDetails,
    history,
    devices,
    timezone,
    gaEventSend,
    searchField,
    sorting,
  } = props

  const goToDetails = (device) => {
    const { deviceId } = device

    putDeviceDetails(device)
    history.push(`/devices/${DEVICE_TYPES.arc.path}/${deviceId}`)
  }

  const locationCount = (searchDevice) =>
    uniq(
      devices
        .filter((device) => searchDevice.serialNumber === device.serialNumber)
        .map((device) => device.propertyName)
    ).length

  const getPageData = useMemo(() => {
    const uniqueDevices = uniqBy(
      orderBy(devices, (device) => device.created, ['desc']),
      (device) => device.serialNumber
    )

    return devices?.length ? (
      orderBy(
        uniqueDevices,
        (device) =>
          sorting.DEVICE_SN
            ? device.serialNumber.toLowerCase()
            : device.created,
        [sorting.DEVICE_SN ? 'asc' : 'desc']
      )
        .filter(
          (device) =>
            !searchField ||
            device.serialNumber
              .toLowerCase()
              .includes(searchField.toLowerCase())
        )
        .map((item) => {
          return (
            <DeviceCard
              key={item.id}
              timezone={timezone}
              openDetails={goToDetails}
              device={item}
              gaEventSend={gaEventSend}
              locationCount={locationCount(item)}
            />
          )
        })
    ) : (
      <EmptyDevicesPageComponent />
    )
  }, [devices, searchField, sorting])

  return (
    <>{loadDevicesError ? <ErrorHasOccurred hideLogo={true} /> : getPageData}</>
  )
}

const mapDispatchToProps = (dispatch) => ({
  putDeviceDetails: (device) => dispatch(actions.putDeviceDetails(device)),
  gaEventSend: gaEventAction(dispatch),
})

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceListReducer },
    rootReducer,
  } = state

  return {
    devices: ARCDeviceListReducer.devices,
    loadDevicesError: ARCDeviceListReducer.loadDevicesError,
    timezone: rootReducer.timezone,
    searchField: ARCDeviceListReducer.searchField,
    sorting: ARCDeviceListReducer.sorting,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ARCDeviceList)
