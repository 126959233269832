import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import Loader from '../../../../components/_shared/loader/Loader'
import DevicesSearch from '../../_shared/components/DevicesSearch'
import devicesActions from '../../_shared/services/devices-actions'
import AddDevice from './components/AddDevice'
import UVGIDeviceList from './components/UVGIDevicesList'
import styles from './style'
import LocationFilter from './components/locationFilter/LocationFilter'
import { connect } from 'react-redux'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import actions from './services/uvgiDeviceList-actions'
import { DEVICES_DOCUMENT_TITLE } from '../../_shared/services/devices-constants'
import usePageVisibility from 'use-page-visibility'

const UVGIDeviceListContainer = (props) => {
  const {
    loadLocations,
    history,
    isLoading,
    isEditView,
    startPollingModification,
    stopPollingModification,
    clearNodes,
    networkError,
    polling,
    isMobile,
    searchBySn,
    resetState,
    classes,
    locationIds,
  } = props

  useEffect(() => {
    document.title = DEVICES_DOCUMENT_TITLE

    loadLocations()

    return () => {
      stopPollingModification()
      clearNodes()
      resetState()
    }
  }, [])

  const handleVisibilityChange = (visible) => {
    if (!visible) {
      stopPollingModification()
    } else if (!polling) {
      startPollingModification()
    }
  }

  usePageVisibility(handleVisibilityChange)

  return (
    <div className={classes.devicesPageWrapper}>
      <Loader isLoading={isLoading && !networkError}>
        {networkError ? (
          <NetworkError />
        ) : (
          <>
            <div className={classes.buttonsWrapper}>
              <LocationFilter />
              {!isMobile && isEditView && <AddDevice />}
            </div>
            <div className={classes.search}>
              <DevicesSearch onSearch={searchBySn} />
            </div>
            <div className={classes.devicesPage}>
              <UVGIDeviceList history={history} />
            </div>
            {isMobile && isEditView && <AddDevice />}
          </>
        )}
      </Loader>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceListReducer },
    rootReducer,
  } = state

  return {
    locationIds: UVGIDeviceListReducer.locationFilter?.ids || [],
    isLoading: UVGIDeviceListReducer.isLoading,
    isEditView: !rootReducer.isOperatorView,
    devices: UVGIDeviceListReducer.list,
    networkError: rootReducer.networkError,
    polling: UVGIDeviceListReducer.polling,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadLocations: () => dispatch(checkConnection(() => actions.loadLocations())),
  resetActionError: () => dispatch(actions.resetActionError()),
  startPollingModification: () => dispatch(actions.startPollingModification()),
  stopPollingModification: () => dispatch(actions.stopPollingModification()),
  clearNodes: () => dispatch(devicesActions.clearNodes()),
  searchBySn: (content) => dispatch(actions.searchBySn(content)),
  resetState: () => dispatch(actions.resetState()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(UVGIDeviceListContainer)))
