import React, { useRef } from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withStyles } from '@material-ui/core/styles'
import StatusDot from '../../../../_shared/components/statusDot/StatusDot'
import {
  EVENT_DEVICE_STATUS,
  DEVICE_STATUS,
} from '../../../../_shared/services/devices-constants'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import {
  withMediaQuery,
  getLocationPath,
  getDevicePath,
} from '../../../../../../services/root/root-service'
import TooltipWrapper from '../../../../_shared/components/tooltipWrapper/TooltipWrapper'

const UVGIDeviceCard = (props) => {
  const { history, device, classes, isMobile, activeTab } = props
  const {
    locationPath,
    deviceStatus,
    faultStatus,
    deviceId,
    deviceNickname,
    serialNumber,
  } = device

  const nicknameRef = useRef()

  const path = locationPath || []
  const [location, locationPathText] = getLocationPath(path, true)

  const goToDetails = () => {
    const path = getDevicePath(activeTab)

    history.push(`/devices/${path}/${deviceId}`)
  }

  return (
    <div className={classes.deviceCard} onClick={goToDetails}>
      <div className={classes.info}>
        <div className={classes.statusDotContainer}>
          <StatusDot status={faultStatus || deviceStatus} />
        </div>

        <div className={classes.columnContainer}>
          <div>
            <p className={classes.secondaryText}>
              {deviceNickname && serialNumber}
            </p>
            <TooltipWrapper
              title={deviceNickname || serialNumber}
              isMobile={isMobile}
              nicknameRef={nicknameRef}
            >
              <Typography className={classes.mainText} noWrap ref={nicknameRef}>
                {deviceNickname || serialNumber}
              </Typography>
            </TooltipWrapper>
          </div>

          <div>
            <p className={classes.secondaryText}>{DEVICE_STATUS.status}</p>
            <Typography className={classes.mainText} noWrap>
              {EVENT_DEVICE_STATUS(deviceStatus, faultStatus)}
            </Typography>
          </div>
        </div>

        <div className={classes.columnContainer}>
          <div>
            <Typography className={classes.secondaryText} noWrap>
              {locationPathText}
            </Typography>
            <Typography className={classes.mainText} noWrap>
              {location}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.navigationRowItem}>
        <ArrowForwardIosIcon className={classes.rowIcon} />
      </div>
    </div>
  )
}

export default withMediaQuery(withStyles(styles)(UVGIDeviceCard))
